<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      paginator
      row-hover
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column
        :sortable="true"
        field="po_no"
        header="No"
        style="min-width: 13rem"
      >
        <template #body="{ data }">
          {{ data.po_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        :sortable="true"
        filter-field="order_between"
        field="po_at"
        header="Tanggal"
        style="min-width: 11rem"
      >
        <template #body="{ data }">
          {{ formatDate(data.po_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            ref="calendarFilter"
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        :sortable="true"
        field="supplier.nama"
        sort-field="supplier"
        filter-field="supplier"
        header="Supplier"
        style="min-width: 12rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        :sortable="true"
        field="golongan.nama"
        sort-field="golongan"
        filter-field="golongan"
        header="Golongan"
        style="min-width: 12rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        :sortable="true"
        field="status"
        header="Status"
        style="min-width: 12rem"
      >
        <template #body="{ data }">
          <div :class="'status-badge status-' + data.status">
            {{ data.status }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="['sent,rcvd', 'sent', 'rcvd']"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column>
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <Button
              type="button"
              icon="pi pi-print"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('print', data)"
            />
            <Button
              v-if="data.status === 'sent'"
              type="button"
              icon="pi pi-tag"
              class="p-button-outlined p-button-secondary p-button-text"
              v-tooltip.top="'Set RCVD Status'"
              @click="$emit('setrcvd', data)"
            />
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="card">
          <DataTable
            v-model:expandedRows="expandedRowsObat"
            :value="slotProps.data.obat"
            :scrollable="true"
            :rowClass="flaggedRowClass"
            class="p-datatable-sm"
            scrollHeight="400px"
            showGridlines
          >
            <Column style="min-width: 3rem; flex: initial" header="" expander />
            <Column field="nama_obat" header="Obat" style="min-width: 19rem" />
            <Column field="quantity" header="QTY" style="min-width: 5rem" />
            <Column field="satuan" header="Satuan" style="min-width: 7rem" />
            <Column
              field="harga"
              header="Harga"
              dataType="numeric"
              style="
                min-width: 9rem;
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
              "
            >
              <template #body="{ data }">
                {{ formatCurrency(data.harga) }}
              </template>
            </Column>
            <Column v-if="slotProps.data.status !== 'rcvd'">
              <template #body="{ data }">
                <Button
                  v-if="qtyCheck(data)"
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-outlined p-button-secondary p-button-text"
                  v-tooltip.top="'Tambah batch'"
                  @click="$emit('addbatch', data)"
                />
              </template>
            </Column>
            <Column>
              <template #body="{ data }">
                <div
                  v-tooltip="
                    flagged.includes(data.id)
                      ? 'Batch Wajib Diisi'
                      : 'Quantity Tidak Sesuai'
                  "
                >
                  <Button
                    v-if="qtyCheck(data)"
                    type="button"
                    icon="pi pi-flag-fill"
                    class="p-button-outlined p-button-secondary p-button-text"
                    disabled
                  />
                </div>
              </template>
            </Column>
            <Column v-if="slotProps.data.status !== 'rcvd'">
              <template #body="{ data }">
                <Button
                  v-if="data.batch.length == 0"
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-outlined p-button-danger p-button-text"
                  v-tooltip.top="'Hapus obat'"
                  @click="$emit('deleteObat', data)"
                />
              </template>
            </Column>
            <template #expansion="detail">
              <grid-batch
                :items="detail.data.batch"
                :show-button="slotProps.data.status !== 'rcvd'"
                :is-purchase="true"
                :detail-id="detail.data.id"
                @delete="onDeleteBatch"
              />
            </template>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'
import CalendarFilter from '@/components/CalendarFilter'
import GridBatch from '@/components/batch/GridBatch'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    flagged: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    CalendarFilter,
    GridBatch,
  },
  data() {
    return {
      expandedRows: [],
      expandedRowsObat: [],
      options: this.gridOptions,
      filters: {
        po_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        order_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
        golongan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: 'sent,rcvd', matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  watch: {
    'options.filters'(value) {
      if (!value.order_between.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
    },
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    qtyCheck(data) {
      const totalQuantity = data.batch.reduce((tot, el) => tot + el.quantity, 0)
      return totalQuantity < data.quantity
    },
    onDeleteBatch(e, pod_id) {
      this.$emit('deletebatch', e.data, pod_id)
    },
    flaggedRowClass(data) {
      return this.flagged.includes(data.id) ? 'flagged-row' : ''
    },
  },
}
</script>

<style scoped>
div >>> .flagged-row {
  background-color: #ffdb99 !important;
}
</style>
