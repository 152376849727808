<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Barang keluar</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Gudang / Barang keluar</span>
      </div>
    </div>
    <div class="card bg-white">
      <grid-barang-keluar
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :flagged="flagged"
        @request="onRequestData"
        @setpick="onConfirmStatusPick"
        @addbatch="onConfirmBatch"
        @deletebatch="onConfirmDeleteBatch"
        @print="onPrint"
      />
    </div>
    <Dialog
      header="Set status"
      v-model:visible="dialogPick"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      closable
    >
      <div class="confirmation-content">
        <span
          >Status Sales Order <strong>{{ sales_order.sp_no }}</strong> akan
          diset menjadi PICK. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Set status"
          icon="pi pi-tag"
          class="p-button-text"
          @click="onSetStatusPick"
        />
      </template>
    </Dialog>
    <Dialog
      header="Tambah batch"
      v-model:visible="dialogBatch"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      closable
    >
      <form-batch
        :obat="obat"
        :listBatch="list_batch"
        @close="onCloseBatch"
        @save="onSaveBatch"
      />
    </Dialog>
    <Dialog
      header="Hapus batch"
      v-model:visible="dialogHapusBatch"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div class="confirmation-content">
        <span
          >Batch <strong>{{ batch.no }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus Batch"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteBatch"
        />
      </template>
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import GudangKeluarService from '@/services/GudangKeluarService'
import BatchService from '@/services/BatchService'
import GridBarangKeluar from '@/components/gudang/GridBarangKeluar'
import FormBatch from '@/components/batch/FormBatch'
import errorHandler from '@/helpers/error-handler'
import { saveAs } from 'file-saver'

export default {
  components: {
    GridBarangKeluar,
    FormBatch,
  },
  data() {
    return {
      batch: {},
      sales_order: {},
      list_batch: [],
      obat: {
        id: null,
        nama: null,
      },
      dataService: null,
      dialogPick: false,
      dialogBatch: false,
      dialogHapusBatch: false,
      dialogViewer: false,
      pdfSource: '',
      pdfTitle: 'PDF Viewer',
      pdfFilename: '',
      isLoading: false,
      items: [],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'sp_at',
        sortOrder: 1,
        filters: null,
      },
      flagged: [],
    }
  },
  created() {
    this.dataService = new GudangKeluarService()
    this.batchService = new BatchService()
  },
  mounted() {
    this.loadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      let filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Sales Order', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onConfirmStatusPick(data) {
      this.sales_order = data
      this.dialogPick = true
    },
    onSetStatusPick() {
      // Validasi batch
      const obat = this.sales_order.obat
      let isValid = true

      obat.forEach((el) => {
        if(el.batch !== undefined){
          if(el.batch !== null){
            if(el.batch.length === 0){
              isValid = false
            }
          }else{
            isValid = false
          }
        }else{
          isValid = false
        }

        if (!this.flagged.includes(el.id)) {
          this.flagged.push(el.id)
        }
      })

      if (!isValid) {
        this.dialogPick = false
        this.$toast.add({
          severity: 'warn',
          summary: 'Data batch',
          detail: 'Data batch tidak valid, harap cek kembali.',
          life: 3000,
        })

        return true
      }

      this.batchService
        .setPick(this.sales_order.id)
        .then((res) => {
          if (res.data.message === 'OK') {
            this.dialogPick = false
            this.$toast.add({
              severity: 'success',
              summary: 'Sales Order',
              detail: 'Status sales order berhasil diubah',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Set Pick', this)
        })
    },
    onConfirmBatch(e) {
      this.obat.nama = e.nama_obat
      this.obat.id = e.obat_id
      this.obat.quantity = e.quantity_tkcl
      this.obat.sod_id = e.id
      this.batchService.get(e.obat_id).then((res) => {
        this.list_batch = res.data.data

        this.dialogBatch = true
      })
    },
    onCloseBatch() {
      this.dialogBatch = false
    },
    onSaveBatch(form) {
      this.isLoading = true

      this.batchService
        .add(form)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Batch',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Batch', this)
        })
        .finally(() => (this.isLoading = false))

      this.dialogBatch = false
    },
    onConfirmDeleteBatch(e, sod_id) {
      this.batch.no = e.batch_no
      this.batch.batch_id = e.id
      this.batch.sod_id = sod_id
      this.dialogHapusBatch = true
    },
    onDeleteBatch() {
      this.batchService
        .delete(this.batch)
        .then((res) => {
          if (res.data.status === 200) {
            this.dialogHapusBatch = false
            this.$toast.add({
              severity: 'success',
              summary: 'Batch',
              detail: 'Data batch berhasil dihapus',
              life: 3000,
            })
            this.loadGridData()
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Batch',
              detail: 'Data batch gagal dihapus',
              life: 3000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Hapus batch', this)
        })
    },
    onPrint(data) {
      this.batchService
        .printKeluar(data.id)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(blob)
          this.pdfSource = pdfUrl + '#toolbar=0'
          this.pdfFilename = 'barang-keluar.pdf'
          this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
          this.dialogViewer = true
        })
        .catch((err) => {
          errorHandler(err, 'Print barang keluar', this)
        })
    },
    onPrintPdf() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onDownloadPdf() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
  },
}
</script>
