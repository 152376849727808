import http from '../clients/Axios'

export default class GudangKeluarService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    let sort = sortOrder === 1 ? '-' + sortField : sortField

    sort += ',-id'

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (el === 'status' && filters[el].value === null) {
          filters_param = filters_param + '&filter[' + el + ']=valid,pick'
        } else if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    } else {
      filters_param = '&filter[status]=valid,pick'
    }

    const res = await http.get(
      `/sales/order/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}&timestamp=${new Date().getTime()}`
    )

    return res.data
  }

  async update(form) {
    const res = await http.put(`/sales/order/${form.id}`, form)
    return res
  }
}
