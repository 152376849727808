<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :lazy="true"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="sp_no" header="No" style="min-width: 13rem" sortable>
        <template #body="{ data }">
          {{ data.sp_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        filter-field="order_between"
        field="sp_at"
        header="Tanggal"
        style="min-width: 11rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.sp_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            ref="calendarFilter"
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pelanggan.nama"
        sort-field="pelanggan"
        filter-field="pelanggan"
        header="Pelanggan"
        style="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="golongan.nama"
        sort-field="golongan"
        filter-field="golongan"
        header="Golongan"
        style="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column sortable field="status" header="Status" style="min-width: 15rem">
        <template #body="{ data }">
          <div :class="'status-badge status-' + data.status">
            {{ data.status }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="['valid,pick', 'valid', 'pick']"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <!-- <Column
        field="jumlah"
        header="Total"
        style="flex-grow: 1; min-width: 180px; justify-content: flex-end"
        sortable
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column> -->
      <Column>
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <Button
              type="button"
              icon="pi pi-print"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('print', data)"
            />
            <Button
              v-if="data.status === 'valid'"
              type="button"
              icon="pi pi-tag"
              class="p-button-outlined p-button-secondary p-button-text"
              v-tooltip.top="'Set PICK Status'"
              @click="$emit('setpick', data)"
            />
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="card">
          <DataTable
            v-model:expandedRows="expandedRowsObat"
            :value="slotProps.data.obat"
            class="p-datatable-sm"
            scrollHeight="400px"
            :rowClass="flaggedRowClass"
            scrollable
            showGridlines
          >
            <Column style="min-width: 3rem; flex: initial" header="" expander />
            <Column field="nama_obat" header="Obat" style="min-width: 15rem" />
            <Column field="quantity" header="QTY" style="min-width: 3rem" />
            <Column field="satuan" header="Satuan" style="min-width: 5rem" />
            <Column
              field="quantity_tkcl"
              header="QTY terkecil"
              style="min-width: 5rem"
            />
            <Column
              field="satuan_tkcl"
              header="Satuan terkecil"
              style="min-width: 5rem"
            />
            <Column field="harga" header="Harga" style="min-width: 3rem">
              <template #body="{ data }">
                {{ formatCurrency(data.harga) }}
              </template>
            </Column>
            <Column
              field="diskon"
              header="Diskon (%)"
              style="min-width: 3rem"
            />
            <Column
              field="harga_diskon"
              header="Harga diskon"
              style="flex-grow: 1; min-width: 3rem; justify-content: flex-end"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.harga_diskon) }}
              </template>
            </Column>
            <Column
              field="dpp"
              header="Harga akhir"
              style="flex-grow: 1; min-width: 5rem; justify-content: flex-end"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.dpp) }}
              </template>
            </Column>
            <Column
              field="harga_total"
              header="Jumlah"
              style="flex-grow: 1; min-width: 9rem; justify-content: flex-end"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.harga_total) }}
              </template>
            </Column>
            <Column
              v-if="
                slotProps.data.status !== 'pick' ||
                (slotProps.data.status === 'pick' &&
                  (slotProps.data.status_si === 'pen1' ||
                    slotProps.data.status_si === 'pen2'))
              "
            >
              <template #body="{ data }">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-outlined p-button-secondary p-button-text"
                  v-tooltip.top="'Tambah batch'"
                  @click="$emit('addbatch', data)"
                />
              </template>
            </Column>
            <Column>
              <template #body="{ data }">
                <div
                  v-tooltip="
                    flagged.includes(data.id)
                      ? 'Batch Wajib Diisi'
                      : 'Quantity Tidak Sesuai'
                  "
                >
                  <div class="flex justify-content-end align-items-end">
                    <Button
                      v-if="qtyCheck(data)"
                      type="button"
                      icon="pi pi-flag-fill"
                      class="
                        p-button-outlined p-button-secondary p-button-text
                        mr-2
                      "
                      disabled
                    />
                  </div>
                </div>
              </template>
            </Column>
            <template #expansion="detail">
              <grid-batch
                :items="detail.data.batch"
                :show-button="
                  slotProps.data.status !== 'pick' ||
                  (slotProps.data.status === 'pick' &&
                    (slotProps.data.status_si === 'pen1' ||
                      slotProps.data.status_si === 'pen2'))
                "
                :detail-id="detail.data.id"
                @delete="onDeleteBatch"
              />
            </template>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatNumber, formatDate } from '@/helpers'
import CalendarFilter from '@/components/CalendarFilter'
import GridBatch from '@/components/batch/GridBatch'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    flagged: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    CalendarFilter,
    GridBatch,
  },
  data() {
    return {
      expandedRows: [],
      expandedRowsObat: [],
      options: this.gridOptions,
      filters: {
        sp_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        order_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pelanggan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        golongan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: 'valid,pick', matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  watch: {
    'options.filters'(value) {
      if (!value.order_between.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
    },
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatNumber(value) {
      return formatNumber(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
    /* showAddBatch(data) {
      const totalQuantity = data.batch.reduce(function (total, item) {
        return total + item.quantity
      }, 0)
      return !(data.quantity_tkcl == totalQuantity)
    }, */
    qtyCheck(data) {
      const totalQuantity = data.batch.reduce((tot, el) => tot + el.quantity, 0)
      // return totalQuantity < data.quantity
      return !(data.quantity_tkcl === totalQuantity)
    },
    onDeleteBatch(e, sod_id) {
      this.$emit('deletebatch', e.data, sod_id)
    },
    flaggedRowClass(data) {
      return this.flagged.includes(data.id) ? 'flagged-row' : ''
    },
  },
}
</script>

<style scoped>
div >>> .flagged-row {
  background-color: #ffdb99 !important;
}
</style>
