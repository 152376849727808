<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <p>
        Set batch untuk produk <strong>{{ obat.nama }}</strong>
      </p>
      <div class="formgrid grid">
        <div class="field col-12 md:col-7">
          <label
            :class="{
              'p-error': v$.batch.id.$invalid && submitted,
            }"
            >Batch</label
          >
          <Dropdown
            v-model="v$.batch.id.$model"
            :options="listBatch"
            :class="{
              'p-invalid': v$.batch.id.$invalid && submitted,
            }"
            dataKey="id"
            optionLabel="batch_no"
            placeholder="Pilih batch"
            class="w-full"
            input-class="w-full"
            @change="updateStockAvailable"
          >
            <template #value="slotProps">
              <div class="p-dropdown-car-value" v-if="slotProps.value">
                <span>{{ slotProps.value.batch_no }}</span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="p-dropdown-car-option">
                <div>
                  <strong>{{ slotProps.option.batch_no }}</strong
                  ><br />
                  Expired: {{ formatDate(slotProps.option.expired_at) }}<br />
                  Stock: {{ slotProps.option.quantity }}<br />
                  Supplier: {{ slotProps.option.nama_supplier }}
                </div>
              </div>
            </template>
          </Dropdown>
          <small
            v-if="
              (v$.batch.id.$invalid && submitted) ||
              v$.batch.id.$pending.$response
            "
            class="p-error"
            >{{ v$.batch.id.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-5">
          <label
            :class="{
              'p-error': v$.batch.quantity.$invalid && submitted,
            }"
            >Quantity</label
          >
          <InputNumber
            v-model="v$.batch.quantity.$model"
            :class="{
              'p-invalid': v$.batch.quantity.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
            @keyup.enter="handleSubmit(!v$.$invalid)"
          />
          <span v-if="v$.batch.quantity.$error && submitted">
            <span
              v-for="(error, index) of v$.batch.quantity.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </span>
          </span>
          <small
            v-else-if="
              (v$.batch.quantity.$invalid && submitted) ||
              v$.batch.quantity.$pending.$response
            "
            class="p-error"
            >{{ v$.batch.quantity.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          class="p-button-text mr-2"
          @click="$emit('close')"
        />
        <Button
          :loading="isLoading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, maxValue } from '@vuelidate/validators'
import { formatDate } from '@/helpers'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    obat: {
      type: Object,
      default() {
        return {}
      },
    },
    listBatch: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      batch: {
        id: 0,
        quantity: 0,
      },
      isLoading: false,
      submitted: false,
      required_qty: 0,
    }
  },
  computed: {
    max_qty() {
      let result = this.obat.quantity
      if (this.required_qty > 0 && this.required_qty !== this.obat.quantity) {
        result = this.required_qty
      }
      return result
    },
  },
  methods: {
    formatDate(value) {
      return formatDate(value)
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const form = {}

      form.sod_id = this.obat.sod_id
      form.batch_id = this.batch.id.id
      form.quantity = this.batch.quantity

      this.$emit('save', form)
    },
    updateStockAvailable(e) {
      if (this.obat.quantity !== e.value.quantity) {
        this.required_qty = e.value.quantity

        if(this.obat.quantity < e.value.quantity){
          this.batch.quantity = this.obat.quantity
        }
        if(this.obat.quantity > e.value.quantity){
          this.batch.quantity = e.value.quantity
        }
      }
    },
  },
  validations() {
    return {
      batch: {
        id: {
          required: helpers.withMessage('Batch harus diisi.', required),
        },
        quantity: {
          required: helpers.withMessage('Qty harus diisi.', required),
          maxValue: maxValue(this.max_qty),
        },
      },
    }
  },
}
</script>
